import { useEffect } from "react";
import { api } from "../service/api";
import logo from "../assets/Shell-helix-biggie.png";
import { useAuth } from "../context/AuthContext";
import { login, logout } from "../service/auth";

const SpinnerComponent = () => {
  const { setAuthenticated, setUser } = useAuth();

  // ====================VERIFY IF USER IS AUTHENTICATED================
  // useEffect(async () => {
  //   try {
  //     const response = await api.get("/auth/profile");

  //     setUser(response.data.data);
  //     setAuthenticated(true);
  //     login(response.data.API_TOKEN);
  //   } catch (error) {
  //     logout();
  //     setAuthenticated(false);
  //   }
  // }, []);

  return (
    <div className="fallback-spinner h-10">
      <img className="fallback-logo" src={logo} alt="logo" />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export { SpinnerComponent };
