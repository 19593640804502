import axios from "axios";
import { getToken } from "./auth";

//LOCAL SERVER
  // export const baseURL = "http://localhost:8001";

   //UAT SERVER
   export const baseURL = "https://vivo-distro-backend.siglonet.com";


    //LIVE SERVER
    // export const baseURL = "https://distro-backend.lubesvemoz.tech";

const api = axios.create({
  baseURL: baseURL + "/api",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { api };
